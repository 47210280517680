import React, { useState, useEffect } from 'react';
import { Star } from 'lucide-react';
import './review.css';

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch('https://api.apify.com/v2/datasets/yNtVxIhW7MJhAlx1B/items?token=apify_api_iImp4r20pIA3QFDA2EFQy1xs43PG8k0mi9Tv');
        const data = await response.json();
        setReviews(data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching reviews:', error);
        setIsLoading(false);
      }
    };

    fetchReviews();
  }, []);
  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <Star 
        key={index} 
        color={index < rating ? 'gold' : 'gray'} 
        fill={index < rating ? 'gold' : 'none'}
      />
    ));
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="testimonial-container">
      <div className="testimonial-header">
        <h2 className="testimonial-title">Testimonials</h2>
        <p className="testimonial-subtitle">
          Hear what our customers have to say about their experiences
        </p>
      </div>

      <div className="testimonial-grid">
        {reviews.slice(0, 3).map((review, index) => (
          <div key={index} className="testimonial-card">
            <img 
              src={review.reviewerPhotoUrl} 
              alt={review.name}
              className="testimonial-avatar"
            />
            <h3 className="testimonial-name">{review.name}</h3>
            {/* <h4 className="testimonial-role">{review.categoryName}</h4> */}
            <p className="testimonial-text">
              {review.text.slice(0, 150)}...
            </p>
            <div className="testimonial-stars">
              {renderStars(review.stars)}
            </div>
            <a 
              href={review.reviewUrl} 
              target="_blank" 
              rel="noopener noreferrer"
              className="testimonial-link"
            >
              Read Full Review
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reviews;