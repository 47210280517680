import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './ChatBot.css';
import beepSound from './../../assets/beep.mp3';  // Adjust the path as needed

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const messagesEndRef = useRef(null);

  const API_KEY = 'AIzaSyCGRLcKThysQP0CZWukM6b4_xFoBoUFnsg';
  const API_URL = 'https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent';

  useEffect(() => {
    scrollToBottom();
    if (isOpen && messages.length === 0) {
      addMessage("Hi Namaste! I'm your friendly Nepal trekking assistant. Whether you want trek recommendations, packing tips, or just a chat about Nepal, I'm here to help! What would you like to know? Also, how high have you been in meters?", false);
    }
  }, [messages, isOpen]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const playBeep = () => {
    const audio = new Audio(beepSound);
    audio.play();
  };

  const speak = (text) => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      window.speechSynthesis.speak(utterance);
    }
  };

  const addMessage = (text, isUser) => {
    setMessages(prev => [...prev, { text, user: isUser }]);
    if (!isUser) {
      speak(text);
    }
  };

  const getResponse = async (userInput) => {
    setIsLoading(true);
    const prompt = `You are a friendly and knowledgeable Nepal trekking assistant. Respond to the following user input: "${userInput}". If it's about trek recommendations, include trek names, max altitudes, and brief descriptions. If it's about packing or general trekking advice, provide concise, helpful information. For casual conversation, be friendly and engaging. Use bullet points (- instead of *) for lists. Keep responses concise but informative.`;

    try {
      const response = await axios.post(
        `${API_URL}?key=${API_KEY}`,
        {
          contents: [{
            parts: [{ text: prompt }]
          }]
        },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      );

      const aiResponse = response.data.candidates[0].content.parts[0].text;
      addMessage(aiResponse, false);
    } catch (error) {
      console.error('Error calling AI API:', error);
      addMessage("Oops! I hit a little snag there. Could you try asking that again?", false);
    }
    setIsLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    addMessage(input, true);
    setInput('');
    await getResponse(input);
  };

  const toggleChatbot = () => {
    playBeep();
    setIsOpen(!isOpen); 
  };

  return (
    <div className={`chatbot-wrapper ${isOpen ? 'open' : ''}`}>
      <button className="chatbot-toggle" onClick={toggleChatbot}>
        {isOpen ? 'X' : '🏔️'}
      </button>
      <div className="chatbot-container">
        <div className="chatbot-header">
          <h2>Nepal Trek Buddy</h2>
        </div>
        <div className="chatbot-messages">
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.user ? 'user' : 'ai'}`}>
              {message.text}
            </div>
          ))}
          {isLoading && <div className="message ai">Thinking of the perfect response...</div>}
          <div ref={messagesEndRef} />
        </div>
        <form onSubmit={handleSubmit} className="chatbot-input-form">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Ask me about trekking!"
            disabled={isLoading}
          />
          <button type="submit" disabled={isLoading}>Send</button>
        </form>
      </div>
    </div>
  );
};

export default Chatbot;