import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './styles.css';
import Footer from '../footer/Footer'
import { OrbitProgress } from 'react-loading-indicators';


const BlogList = () => {
    const [blogs, setBlogs] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                setLoading(true); // Start loading
                const response = await axios.get(`https://nodejs-serverless-function-express-henna-theta.vercel.app/api/blogs`);
                if (Array.isArray(response.data)) {
                    setBlogs(response.data);
                    setError(null);
                } else {
                    throw new Error('Invalid data format');
                }
            } catch (error) {
                console.error('Failed to fetch blogs:', error);
                setError(error.response?.data?.message || 'Failed to fetch blogs. Please try again later.');
                setBlogs([]);
            } finally {
                setLoading(false); // End loading
            }
        };

        fetchBlogs();
    }, []);
    
    if (loading) return <div style={{display:"flex", justifyContent:"center", marginTop:"200px"}}><OrbitProgress color="#32cd32" size="large" text="" textColor="" /></div>;
    if (error) return <div className="error-message">Error: {error}</div>;
    if (blogs.length === 0) return <div className="no-blogs">No blogs found. Please add some blogs.</div>;

    return (
               <>
        <div className="blog-container">
        <h1 class="mb-4 text-3xl font-bold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl dark:text-black">Blog List</h1>
            <div className="blog-grid">
                {blogs.map((blog) => (
                    <Link to={`/blog/${blog.slug}`} key={blog.slug} className="blog-card">
                        <div className="blog-card-inner">
                            {/* Front side */}
                            <div className="blog-card-front">
                                {blog.image && <img src={blog.image} alt={blog.title} />}
                                <h2>{blog.title}</h2>
                            </div>

                            {/* Back side */}
                            <div className="blog-card-back">
                            {blog.image && <img src={blog.image} alt={blog.title} />}
                                <h3>....Click to see details about..... <br></br>{blog.title}</h3>
                                {/* <h2>{blog.title}</h2> */}
                                {/* <p>{blog.Slug?.map((word, index) => (
                                    <span key={index} className="keyword">{word}</span>
                                ))}</p> */}
                            </div>
                        </div>
                    </Link>
                ))}
                
            </div>
            
        </div>
       <Footer/>
</>
    );
};

export default BlogList;
