import React, { useState } from 'react';
import './Sidebar.css';
import { FaSearch, FaFilter, FaCalendarAlt } from 'react-icons/fa';
import BookingCalendar from '../bookingCalendar/BookingCalendar';

const Sidebar = ({ onFilterChange, onDateChange }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [duration, setDuration] = useState('');
  const [difficulty, setDifficulty] = useState('');
  const [region, setRegion] = useState('');


  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
    // Call the filter function to apply filters
    onFilterChange({ searchQuery: e.target.value.toLowerCase(), duration, difficulty, region });
  };

  const handleDurationChange = (e) => {
    setDuration(e.target.value);
    // Pass updated filter values to the parent component
    onFilterChange({ searchQuery, duration: e.target.value, difficulty, region });
  };

  const handleDifficultyChange = (e) => {
    setDifficulty(e.target.value);
    // Pass updated filter values to the parent component
    onFilterChange({ searchQuery, duration, difficulty: e.target.value, region });
  };

  const handleRegionChange = (e) => {
    setRegion(e.target.value);
    // Pass updated filter values to the parent component
    onFilterChange({ searchQuery, duration, difficulty, region: e.target.value });
  };

  return (
    <div className="sidebar" style={{ margin: "125px 20px 0 0"}}>
      <h3 className="sidebar-title">Filter Packages</h3>

      {/* Search Bar */}
      <div className="sidebar-section">
        <label htmlFor="search">
          <FaSearch className="sidebar-icon" /> Search
        </label>
        <input
          id="search"
          type="text"
          placeholder="Search packages..."
          value={searchQuery}
          onChange={handleSearch}
          className="sidebar-input"
        />
      </div>

      {/* Duration Filter */}
      <div className="sidebar-section">
        <label>
          <FaFilter className="sidebar-icon" /> Duration
        </label>
        <select
          value={duration}
          onChange={handleDurationChange}
          className="sidebar-select"
        >
          <option value="">All</option>
          <option value="3 Days">3 days</option>
          <option value="4 Days">4 days</option>
          <option value="6 Days">6 days</option>
          <option value="10 Days">10 days</option>
          <option value="11 Days">11 days</option>
          <option value="12 Days">12 days</option>
          <option value="16 Days">16 days</option>
          <option value="Flexible ">Flexible </option>
        </select>
      </div>

      {/* Difficulty Filter */}
      <div className="sidebar-section">
        <label>
          <FaFilter className="sidebar-icon" /> Difficulty
        </label>
        <select
          value={difficulty}
          onChange={handleDifficultyChange}
          className="sidebar-select"
        >
          <option value="">All</option>
          <option value="Easy to Moderate">Easy</option>
          <option value="Moderate">Moderate</option>
          <option value="Moderate to Challenging">Moderate to Challenging</option>
          {/* <option value="Moderate to Challenging">Difficult</option> */}
        </select>
      </div>

      {/* Region Filter */}
      <div className="sidebar-section">
        <label>
          <FaFilter className="sidebar-icon" /> Region
        </label>
        <select
          value={region}
          onChange={handleRegionChange}
          className="sidebar-select"
        >
          <option value="">All Nepal</option>
          {/* <option value="himalayas">Himalayas</option> */}
          <option value="everest">Everest</option>

          <option value="annapurna">Annapurna</option>
          <option value="langtang">Langtang</option>
          <option value="manaslu region">Manaslu</option>
        </select>
      </div>
    </div>
  );
};

export default Sidebar;
