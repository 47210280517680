import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './PackageList.css';
import Confetti from 'react-dom-confetti';
import { FaGlobe, FaMapMarkerAlt, FaWalking, FaMountain, FaListAlt } from 'react-icons/fa';
import { LiaCalendarDaySolid } from "react-icons/lia";
import BookingCalendar from '../bookingCalendar/BookingCalendar';
import Payment from '../Payment/Payment';
import CampaignSection from '../campaign/CampaignSection';
import Footer from '../footer/Footer';
import Sidebar from '../Sidebar/Sidebar';
import { Atom } from 'lucide-react';
import { OrbitProgress } from 'react-loading-indicators';




const PackageList = () => {
  const [packages, setPackages] = useState([]);
  const [packageDetails, setPackageDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);

  // const [selectedPackage, setSelectedPackage] = useState(null);
  // const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  // const [bookingDetails, setBookingDetails] = useState(null);
  const navigate = useNavigate();
  // const [searchText, setSearchText] = useState('');


  const [filteredPackages, setFilteredPackages] = useState([]);
  useEffect(() => {
    setFilteredPackages(packages);
  }, [packages]);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/packages');
        if (Array.isArray(response.data)) {
          setPackages(response.data);
        } else {
          throw new Error('Invalid data format');
        }
      } catch (error) {
        console.error('Error fetching packages:', error);
        setError(error.response?.data?.message || 'Failed to load packages');
      } finally {
        setLoading(false);
      }
    };
    fetchPackages();
  }, []);

  // Updated to use slug instead of id
  const handlePackageClick = (slug) => {
    try {
      setLoading(true);
      axios.get(`https://nodejs-serverless-function-express-henna-theta.vercel.app/api/packages/slug/${slug}`);
      console.log(slug)
      // setPackageDetails(response.data)
      console.log(navigate(`/packages/${slug}`))
      navigate(`/packages/${slug}`);

    } catch (error) {
      console.error('Error fetching package:', error);
      setError(error.response?.data?.message || 'Failed to load package details');
    }
  };
  const handleDateChange = (selectedDate) => {
    console.log('Selected date:', selectedDate);
    // Handle the date change logic here
  };

  // const handleBookNow = (pkg) => {
  //   setSelectedPackage(pkg);
  // };

  // const handleCloseBooking = () => {
  //   setSelectedPackage(null);
  // };

  // const handleSearchChange = (event) => {
  //   setSearchText(event.target.value.toLowerCase()); // Search should be case-insensitive
  // };

  // const handleBooking = (date, formData) => {
  //   setBookingDetails({ packageData: selectedPackage, date, formData });
  //   setIsPaymentOpen(true);
  // };

  // const handlePaymentSuccess = () => {
  //   setShowConfetti(true);
  //   setTimeout(() => setShowConfetti(false), 3000);
  //   setIsPaymentOpen(false);
  //   setSelectedPackage(null);
  // };

  // const handlePaymentCancel = () => {
  //   setIsPaymentOpen(false);
  //   setSelectedPackage(null);
  // };


  const confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  if (loading) return <div style={{ display: "flex", justifyContent: "center", marginTop: "200px" }}><OrbitProgress color="#32cd32" size="large" text="" textColor="" /></div>;
  if (error) return <p>Error: {error}</p>;

  const containerStyle = {
    display: "flex",

    // justifyContent:"center"
    // justifyContent:"space-between"
    // margin: "20%"
  }
  const handleFilterApply = ({ searchQuery, duration, difficulty, region }) => {
    const filtered = packages.filter((pkg) => {
      const matchesSearch = pkg.title.toLowerCase().includes(searchQuery.toLowerCase() || ''); // Search should be case-insensitive
      const matchesDuration = !duration || pkg.duration === duration; // Make sure duration matches
      const matchesDifficulty = !difficulty || pkg.difficulty === difficulty; // Match difficulty
      const matchesRegion = !region || pkg.region.toLowerCase().includes(region.toLowerCase()); // Match region

      return matchesSearch && matchesDuration && matchesDifficulty && matchesRegion;
    });

    console.log('Filtered packages:', filtered); // Log filtered packages for debugging
    setFilteredPackages(filtered); // Update the filtered packages state
  };


  return (
    <>
      <div className="package-page w-full " style={containerStyle}>

        <Sidebar
          onFilterChange={handleFilterApply} // Ensure handleFilterApply is the correct function
          onDateChange={handleDateChange} // If you want to handle date change
        />

        <div><h2 className="text-center  ">All Packages</h2>
          {showConfetti && <Confetti active={showConfetti} config={confettiConfig} />}
          <div className="package-grid ">
            {filteredPackages.map((pkg) => (
              <div
                key={pkg._id}
                className="package-card "
                onClick={() => handlePackageClick(pkg.slug)} // Updated to use slug
              >

                <div className="package-card-inner">
                  {/* Front of the card */}
                  <div className="package-front ">
                    <div className="package-image-container">
                      <img src={pkg.overallImages[0]} alt={pkg.title} className="package-image" />
                      <div className="package-overlay">
                        <span className="difficulty-badge">{pkg.difficulty}</span>
                      </div>
                    </div>
                    <div className="package-details">
                      <h2>{pkg.title}</h2>
                      <div className="trip-details">
                        <div className="detail-item"><FaGlobe className="detail-icon" />{pkg.country}</div>
                        <div className="detail-item"><FaMapMarkerAlt className="detail-icon" />{pkg.region}</div>
                        <div className="detail-item"><FaWalking className="detail-icon" />{pkg.dailyDistance}</div>
                        <div className="detail-item"><FaMountain className="detail-icon" />{pkg.dailyAscent}</div>
                        <div className="detail-item"><FaMountain className="detail-icon" />{pkg.dailyDescent}</div>
                        <div className="detail-item"><LiaCalendarDaySolid className="detail-icon" />{pkg.duration}</div>
                      </div>
                      <h3 className="price">${pkg.price}</h3>
                      <button
                        className="book-now"
                        onClick={(e) => {
                          e.stopPropagation();
                          // handleBookNow(pkg);
                          handlePackageClick(pkg.slug)

                        }}
                      >
                        See details
                      </button>
                    </div>
                  </div>


                  {/* Back of the card */}
                  <div className="package-back">
                   
                    <img src={pkg.overallImages[0]} alt={pkg.title} className="package-image h-auto" />
              
                     <h3>Trip Description</h3>
                    <p className="back-description">{pkg.description}</p>
                    
                    {/* <h3>Itinerary</h3>
                <ul>
                  {pkg.itinerary.map((item, index) => (
                    <li key={index}>
                      <FaListAlt className="detail-icon" />
                      {item.title}
                    </li>
                  ))}
                </ul> */}

                    <button
                      className="book-now back-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePackageClick(pkg.slug)
                      }}
                    >
                      See details
                    </button>
                  </div>

                </div>

              </div>
            ))}

          </div>

          {/* {selectedPackage && (
        <BookingCalendar 
          packageData={selectedPackage} 
          onClose={handleCloseBooking} 
          onBook={handleBooking}
        />
      )} */}

          {/* {isPaymentOpen && (
        <Payment 
          amount={bookingDetails.packageData.price * 0.3}
          onPaymentSuccess={handlePaymentSuccess}
          onPaymentCancel={handlePaymentCancel}
        />
      )} */}

        </div>
      </div>
      <CampaignSection />
      <Footer />
    </>
  );
};

export default PackageList;


