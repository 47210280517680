import React, { useState } from 'react';
import axios from 'axios';
import './CreateBlog.css';

const CreateBlog = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Create a FormData object to send the file and text fields
      const formData = new FormData();
      formData.append('title', title);
      formData.append('content', content);
      formData.append('image', image); // Append the selected file

      const response = await axios.post('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/blogs/create', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      const blogs = await axios.get
      alert('Blog created successfully!');
      setTitle('');
      setContent('');
      setImage(null);
    } catch (error) {
      console.error('Error creating blog:', error);
      alert('Failed to create blog. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]); // Store the selected file
  };

  return (
    <div className="blog-post-container">
      <h2 className="blog-post-title">Create Blog Post</h2>
      <form onSubmit={handleSubmit} className="blog-post-form">
        <div className="form-group">
          <label className="form-label">Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label className="form-label">Content</label>
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
            className="form-textarea"
          />
        </div>
        <div className="form-group">
          <label className="form-label">Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            required
            className="form-input"
          />
        </div>
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? 'Creating Blog...' : 'Create Blog'}
        </button>
      </form>

    </div>
  );
};

export default CreateBlog;
