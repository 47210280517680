import React, { useState } from 'react';
import axios from 'axios';
import '../admin/PackageUpload.css';

const PackageUpload = () => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    country: '',
    region: '',
    duration: '',
    difficulty: '',
    //easy modrate and hard lai choose ani teslai filter garera package category enum:3
    dailyDistance: '',
    dailyAscent: '',
    price: '',
    included: '',
    excluded: '',
    itinerary: [],
  });
  
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
  };
  
  const handleItineraryChange = (index, field, value) => {
    const updatedItinerary = [...formData.itinerary];
    updatedItinerary[index] = {
      ...updatedItinerary[index],
      [field]: value,
    };
    setFormData({ ...formData, itinerary: updatedItinerary });
  };

  const addItineraryDay = () => {
    setFormData((prevData) => ({
      ...prevData,
      itinerary: [
        ...prevData.itinerary, 
        { 
          day: prevData.itinerary.length + 1, 
          title: '', 
          description: '', 
          duration: '',
          distance: '',
          ascent: '',
          descent: ''
        }
      ],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const submitFormData = new FormData();

      // Append all text fields
      Object.keys(formData).forEach(key => {
        if (key === 'itinerary') {
          submitFormData.append('itinerary', JSON.stringify(formData.itinerary));
        } else if (key === 'included' || key === 'excluded') {
          submitFormData.append(key, formData[key]);
        } else {
          submitFormData.append(key, formData[key]);
        }
      });

      // Append files
      files.forEach((file, index) => {
        submitFormData.append('images', file);
      });

      // Send POST request
      const response = await axios.post('https://nodejs-serverless-function-express-henna-theta.vercel.app/api/packages', submitFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      alert('Package uploaded successfully!');
      // Reset form
      setFormData({
        title: '',
        description: '',
        country: '',
        region: '',
        duration: '',
        difficulty: '',
        dailyDistance: '',
        dailyAscent: '',
        price: '',
        included: '',
        excluded: '',
        itinerary: [],
      });
      setFiles([]);
    } catch (error) {
      console.error('Error uploading package:', error);
      alert('Failed to upload package. Please try again.');
    }
    setLoading(false);
  };

  return (
    <div className="package-upload-page">
      <h2>Upload a New Trek Package</h2>
      <form onSubmit={handleSubmit} className="upload-form">
        <input type="text" name="title" placeholder="Title" value={formData.title} onChange={handleInputChange} required />
        <textarea name="description" placeholder="Description" value={formData.description} onChange={handleInputChange} required />
        <input type="text" name="country" placeholder="Country" value={formData.country} onChange={handleInputChange} required />
        <input type="text" name="region" placeholder="Region" value={formData.region} onChange={handleInputChange} required />
        <input type="text" name="duration" placeholder="Duration (e.g., 10 Days)" value={formData.duration} onChange={handleInputChange} required />
        <input type="text" name="difficulty" placeholder="Difficulty" value={formData.difficulty} onChange={handleInputChange} required />
        <input type="text" name="dailyDistance" placeholder="Daily Distance" value={formData.dailyDistance} onChange={handleInputChange} required />
        <input type="text" name="dailyAscent" placeholder="Daily Ascent" value={formData.dailyAscent} onChange={handleInputChange} required />
        <input type="number" name="price" placeholder="Price" value={formData.price} onChange={handleInputChange} required />
        <textarea name="included" placeholder="What's Included (comma separated)" value={formData.included} onChange={handleInputChange} required />
        <textarea name="excluded" placeholder="What's Excluded (comma separated)" value={formData.excluded} onChange={handleInputChange} required />

        <div className="file-upload">
          <label>Upload Images (Maximum 20)</label>
          <input 
            type="file" 
            multiple 
            accept="image/*" 
            onChange={handleFileChange} 
            required
          />
          <small>Selected files: {files.length}</small>
        </div>

        <div className="itinerary-section">
          <h3>Itinerary</h3>
          {formData.itinerary.map((day, index) => (
            <div key={index} className="itinerary-day">
              <h4>Day {index + 1}</h4>
              <input
                type="text"
                placeholder="Title"
                value={day.title}
                onChange={(e) => handleItineraryChange(index, 'title', e.target.value)}
                required
              />
              <textarea
                placeholder="Description"
                value={day.description}
                onChange={(e) => handleItineraryChange(index, 'description', e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Duration"
                value={day.duration}
                onChange={(e) => handleItineraryChange(index, 'duration', e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Distance"
                value={day.distance}
                onChange={(e) => handleItineraryChange(index, 'distance', e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Ascent"
                value={day.ascent}
                onChange={(e) => handleItineraryChange(index, 'ascent', e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Descent"
                value={day.descent}
                onChange={(e) => handleItineraryChange(index, 'descent', e.target.value)}
                required
              />
            </div>
          ))}
          <button type="button" onClick={addItineraryDay}>Add Itinerary Day</button>
        </div>

        <button type="submit" disabled={loading}>
          {loading ? 'Uploading...' : 'Upload Package'}
        </button>
      </form>
    </div>
  );
};

export default PackageUpload;
