import React from "react";
import { MapPin, Users, Globe, Shield, Mountain, Check } from "lucide-react";

const AboutUs = () => {
  return (
    <div className="bg-gradient-to-br from-blue-950 via-blue-900 to-blue-800 text-white py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto jsutify-center">
        {/* Hero Section with Animation */}
        <div className="text-center px-4 sm:px-6 lg:px-8">
  <div className="max-w-4xl mx-auto">
    <h1 className="text-4xl sm:text-5xl lg:text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-300 to-blue-100 tracking-tight mb-4">
    Conquer the Himalayas: Your Ordinary Life Ends Where True Adventure Begins
    </h1>
    <p className="text-lg sm:text-xl text-blue-100 leading-relaxed">
    Transformative Trek that Reshapes Your Identity: Discover why our signature Himalayan expeditions are more than a journey—they're a profound personal metamorphosis. No average tourist routes. No compromises. Just pure, unfiltered adventure that challenges everything you know about yourself.
    </p>
  </div>
</div>
        {/* Responsive Features Grid */}
        <div className="grid lg:grid-cols-2 gap-10 items-center mt-12">
          {/* Features List with Hover Effects */}
          <div className="space-y-6">
            {[
              {
                icon: Users,
                title: "Expert Guides",
                description: "30+ certified professionals with unparalleled Himalayan knowledge",
                color: "text-blue-400"
              },
              {
                icon: Globe,
                title: "Global Expertise",
                description: "Multilingual support bridging cultures across 10+ languages",
                color: "text-green-400"
              },
              {
                icon: Shield,
                title: "Ultimate Safety",
                description: "Cutting-edge safety protocols and comprehensive emergency support",
                color: "text-red-400"
              }
            ].map((feature, index) => (
              <div 
                key={index} 
                className="flex items-center space-x-5 bg-blue-800/30 p-5 rounded-2xl border border-blue-700/50 hover:bg-blue-700/40 transition-all duration-300 transform hover:-translate-y-1 hover:scale-105 shadow-lg"
              >
                <feature.icon className={`${feature.color}`} size={50} strokeWidth={1.5} />
                <div>
                  <h3 className="font-bold text-xl text-blue-200 mb-1">{feature.title}</h3>
                  <p className="text-blue-100 text-sm">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>

          {/* Map and Achievement Stats */}
          <div className="bg-blue-900/30 rounded-3xl p-6 lg:p-8 border border-blue-800/50 space-y-8">
            {/* Animated Map Container */}
            <div className="relative rounded-2xl overflow-hidden shadow-2xl transform transition-transform hover:scale-[1.02]">
              <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d930929.1771926308!2d83.88375351603042!3d28.185790822933768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2d7c9ad462495687%3A0xad66f41f291153ac!2sMantra%20Mountain%20Nepal%20Treks%20PVT%20LTD!5e1!3m2!1sen!2snp!4v1737437235029!5m2!1sen!2snp" 
                className="w-full h-64 lg:h-72" 
                style={{ border: 0 }} 
                allowFullScreen="" 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade">
              </iframe>
              <div className="absolute inset-0 bg-blue-900/20 hover:bg-transparent transition-all duration-300"></div>
            </div>

            {/* Responsive Achievement Stats */}
            <div className="flex flex-col sm:flex-row justify-between space-y-4 sm:space-y-0 sm:space-x-4">
              {[
                { value: "30+", label: "Expert Guides", color: "text-blue-300" },
                { value: "10+", label: "Languages", color: "text-green-300" },
                { value: "100%", label: "Satisfaction", color: "text-red-300" }
              ].map((stat, index) => (
                <div 
                  key={index} 
                  className="flex-1 bg-blue-800/40 rounded-xl p-4 hover:bg-blue-700/50 transition-all transform hover:scale-105 text-center"
                >
                  <h3 className={`text-4xl font-bold ${stat.color} mb-1`}>{stat.value}</h3>
                  <p className="text-blue-100 text-sm">{stat.label}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;