import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Swiper.css';
import { OrbitProgress } from 'react-loading-indicators';

const Swiper = () => {
  const [packages, setPackages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get(
          'https://nodejs-serverless-function-express-henna-theta.vercel.app/api/packages'
        );
        if (Array.isArray(response.data)) {
          setPackages(response.data);
        } else {
          throw new Error('Invalid data format');
        }
      } catch (error) {
        console.error('Error fetching packages:', error);
      }
    };
    fetchPackages();
  }, []);

  const handlePackageClick = (slug) => {
    try {
      navigate(`/packages/${slug}`); // Correctly navigate to the package details page
    } catch (error) {
      console.error('Error navigating to package:', error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % packages.length);
    }, 5000); // Automatically slide every 5 seconds
    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [packages.length]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + packages.length) % packages.length);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % packages.length);
  };

  return (
    <section id="packages">
      <div className="container">
        <h3 className="section-subheading">- Transformative Journeys Await -</h3>
        <h1 className="section-heading">Discover Your True Self in Nepal's Majestic Himalayas</h1>
      </div>
      <div className="custom-swiper">
        {packages.length > 0 ? (
          <>
            <button className="swiper-button prev" onClick={handlePrev}>
              &#10094;
            </button>
            <div className="swiper-slide">
              <div className="package-card">
                <div className="package-image">
                  <img
                    src={packages[currentIndex].overallImages[0]}
                    alt={packages[currentIndex].title}
                  />
                  <div className="package-overlay">
                    <span className="experience-badge">{packages[currentIndex].difficulty}</span>
                  </div>
                </div>
                <div className="package-card-content">
                  <h2>{packages[currentIndex].title}</h2>
                  <div className="package-details">
                    <span className="duration">
                      <i className="fas fa-clock"></i> {packages[currentIndex].duration} Days of
                      Discovery
                    </span>
                  </div>
                  <div className="package-footer">
                    <h3 className="value-proposition">
                      Lifelong Memories from ${packages[currentIndex].price}
                    </h3>
                    <button
                      className="embark-journey"
                      onClick={() => handlePackageClick(packages[currentIndex].slug)}
                    >
                      Embark on Your Journey
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button className="swiper-button next" onClick={handleNext}>
              &#10095;
            </button>
            <button className="swiper-button prev" onClick={handleNext}>
              &#10095;
            </button>
          </>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '200px' }}>
            <OrbitProgress color="#32cd32" size="large" text="" textColor="" />
          </div>
        )}
      </div>
    </section>
  );
};

export default Swiper;
