import styled from "styled-components"
import {Canvas} from '@react-three/fiber'
import { Suspense } from "react"
import { Earth } from "../earth/earth"
import Background from "../Background/background";
// import '../../pages/pagesCss/Globe.css';



const CanvasContainer= styled.div`
wiidth=100%
height=100%;
`;
function Globe(){
    return <CanvasContainer>
        <Canvas  className="globe">
        <Suspense fallback={null}>
        {/* <Earth/> */}
        </Suspense>
        </Canvas>
    </CanvasContainer>
}
export default Globe;