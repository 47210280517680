import React from "react";
import "./CampaignSection.css"; // Add your styling here

const CampaignSection = () => {
  return (
    <div className="campaign-section">
      <div className="campaign-content">
        <h2 className="campaign-heading">Every Step You Take Makes a Difference</h2>
        <p className="campaign-message">
          When you book a trek with <strong>Mantra Mountain</strong>, you’re not just exploring the Himalayas—you’re helping rebuild lives. 
          <strong> 20% of our profits</strong> go directly to our disaster relief fund, bringing hope and resilience to mountain communities 
          impacted by natural calamities.
        </p>
        <blockquote className="campaign-quote">
          "Your journey through the peaks brings light to valleys in need."
        </blockquote>
        <div className="campaign-action">
          <p>
            Together, we can ensure a brighter, safer future for the guardians of the mountains who call these majestic peaks home.
          </p>
          <button className="campaign-button" onClick={() => window.location.href = "/About"}>
            Learn More About Our Impact
          </button>
        </div>
      </div>
    </div>
  );
};

export default CampaignSection;
